import { login, getUserInfo } from '@/api/sys'
import { setItem, getItem } from '@/utility/storage'
import { TOKEN } from '@/constant'
import router from '@/router'

export default {
  namespaced: true,
  state() {
    return {
      token: getItem(TOKEN) || '',
      userInfo: null
    }
  },
  mutations: {
    SET_token(state, payload) {
      state.token = payload
      setItem(TOKEN, payload)
    },
    SET_userInfo(state, payload) {
      state.userInfo = payload
    }
  },
  actions: {
    login({ commit }, payload) {
      return new Promise((resolve, reject) => {
        login(payload)
          .then((data) => {
            if (data.code === 200) {
              commit('SET_token', data.token)
              resolve()
            } else {
              resolve(false) // Return false if code is not 200
            }
          })
          .catch((error) => reject(error))
      })
    },
    getUserInfo({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then(async (data) => {
            if (data.code === 200) {
              commit('SET_userInfo', data.user)
              await dispatch('routes/computedUserRoutes', [], { root: true })
              resolve(true)
            } else {
              resolve(false)
            }
          })
          .catch(() => {
            resolve(false)
          })
      })
    },
    logout({ commit, dispatch }) {
      commit('SET_token', '')
      commit('SET_userInfo', null)
      router.push('/login')
    }
  },
  getters: {}
}
