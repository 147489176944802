import axios from 'axios'
import { ElMessage } from 'element-plus'
import store from '@/store'
const serve = axios.create({
  baseURL: '/api',
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' }
})

// 请求拦截器
serve.interceptors.request.use(
  (config) => {
    // 如果有token，则在请求头中进行携带,方便进行用户鉴权
    if (store.getters.token) {
      config.headers.token = store.getters.token
    }
    return config
  },
  (error) => {
    // 对请求错误做些什么
    ElMessage.error({ message: error.message })
    return Promise.reject(error)
  }
)

// 响应拦截器
serve.interceptors.response.use(
  (response) => {
    // 直接返回响应对象
    return response.data
  },
  (error) => {
    // 处理错误并返回
    const errorMessage = error.response ? error.response.data.message : error.message
    ElMessage.error({ message: errorMessage })
    return Promise.reject(error)
  }
)
export default serve
