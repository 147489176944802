import Layout from '@/layout'

export const publicRoutes = () => {
  return [
    {
      path: '/',
      component: Layout
    },
    {
      path: '/info',
      component: Layout,
      redirect: '/info/info',
      meta: {
        title: '用户管理',
        icon: 'house'
      },
      children: [
        {
          path: 'info',
          name: 'info',
          component: () => import('@/views/Info'),
          meta: {
            title: '个人信息',
            icon: 'user'
          }
        },
        {
          path: 'user-list',
          name: 'user-list',
          component: () => import('@/views/UserList'),
          meta: {
            title: '用户列表',
            icon: 'edit'
          }
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login')
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/Register')
    },
    {
      path: '/:pathMatch(.*)*',
      component: () => import('@/views/404')
    },
    {
      path: '/product',
      name: 'product-management',
      component: Layout,
      meta: {
        title: '商品管理',
        icon: 'goods'
      },
      children: [
        {
          path: 'category',
          name: 'product-category',
          component: () => import('@/views/Product/Category'),
          meta: {
            title: '商品分类'
          }
        },
        {
          path: 'detail',
          name: 'product-detail',
          component: () => import('@/views/Product/Detail'),
          meta: {
            title: '商品列表'
          }
        }
      ]
    },
    {
      path: '/order',
      name: 'order-management',
      component: Layout,
      meta: {
        title: '订单管理',
        icon: 'tickets'
      },
      children: [
        {
          path: 'order',
          component: () => import('@/views/Order'),
          meta: {
            title: '订单列表'
          }
        }
      ]
    },
    {
      path: '/region',
      name: 'region-management',
      component: Layout,
      meta: {
        title: '区域管理',
        icon: 'position'
      },
      children: [
        {
          path: 'region',
          component: () => import('@/views/Region'),
          meta: {
            title: '区域列表'
          }
        }
      ]
    },
    {
      path: '/shop',
      name: 'shop-management',
      component: Layout,
      meta: {
        title: '门店管理',
        icon: 'van'
      },
      children: [
        {
          path: 'shop-list',
          component: () => import('@/views/ShopFront/ShopList'),
          meta: {
            title: '门店列表'
          }
        },
        {
          path: 'shop-goods',
          component: () => import('@/views/ShopFront/ShopGoods'),
          meta: {
            title: '门店商品'
          }
        }
      ]
    }
  ]
}

export default publicRoutes
