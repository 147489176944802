import request from '@/utility/request'
import store from '@/store'

/**
 * 注册
 * @param {*} data
 * @returns Promise
 */
export function register(data) {
  return request({
    url: '/user/register',
    method: 'post',
    data: data
  })
}

/**
 * 登录
 * @param {*} data
 * @returns Promise
 */
export function login(data) {
  return request({
    url: '/user/login',
    method: 'post',
    data: data
  })
}

export function getCode(data) {
  return request({
    url: '/user/sendCode',
    method: 'post',
    data: data
  })
}

/**
 *获取用户信息
 * @returns Promise
 */
export function getUserInfo() {
  return request({
    url: '/user/info',
    method: 'post',
    headers: {
      token: store.getters.token
    }
  })
}
export function updateInfo(data) {
  return request({
    url: '/user/updateInfo',
    method: 'post',
    data: data
  })
}
/**
 * 退出登录
 */
export function logout() {
  return request({ url: '/sys/logout', method: 'post' })
}
