import publicRoutes from '@/router/routes'

export default {
  namespaced: true,
  state() {
    return {
      routes: []
    }
  },
  mutations: {
    SET_routes(state, payload) {
      state.routes = payload
    }
  },
  actions: {
    computedUserRoutes({ commit }, menus) {
      return new Promise((resolve) => {
        // 根据用户信息计算出用户所拥有的路由表
        const userRoutes = getUserRoutes(publicRoutes(), menus)
        commit('SET_routes', userRoutes)
        resolve()
      })
    }
  }
}

/**
 * 根据用户信息计算出用户所拥有的路由表
 * @param {*} publicRoutes
 * @param {*} menus
 */
function getUserRoutes(publicRoutes, menus) {
  const newList = [...publicRoutes]
  newList.forEach((route) => {
    // 从子路由开始向上遍历
    if (route.children && route.children.length) {
      getUserRoutes(route.children, menus)
    }

    if (!(route.children && route.children.length)) {
      const exist = menus.includes(route.name)
      if (!exist) {
        // 过滤掉用户没有权限的路由
        newList.splice(newList.indexOf(route), 1)
      }
    }
  })
  return newList
}
